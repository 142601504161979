@page {
    size: auto;
    margin: 10mm;
}

html,body {
    width: 100%;
    min-width: 1100px;
}

body,
.outer-wrapper {
    height: auto;
}

#outer-wrapper,
.body--debug.body--admin #outer-wrapper,
.body--debug #outer-wrapper,
.body--admin #outer-wrapper {
    padding-top: 0;
}

#outer-wrapper {
    display: block;
}

#debug-holder,
#debug-bar,
#debug-content,
#admin-bar,
header,
footer,
.product__request-sample,
.product__links,
.product__group--form,
.product__sharing,
.filterable-container,
.filterable,
.load-more {
    display: none;
}

.product__group--accordion {
    margin-top: 20px;

    .accordion__wrap {
        max-height: 100%;
    }
}

.slick-track {
    width: 100% !important;
    transform: none !important;
}

    .slick-slide {
        display: none !important;

        &.slick-active {
            display: block !important;
        }
    }

        .slick-arrow {
            opacity: 0;
        }
